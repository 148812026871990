
import 'focus-visible';
import { breakpoints } from '../variables.yml';
import { throttle, getWindowWidth } from './helpers.js';
import './vendor/ajax.js';
import { LoadOMatic } from './vendor/load-o-matic.js';
import EmblaCarousel from 'embla-carousel';
import { addPrevNextBtnsClickHandlers, addTogglePrevNextBtnsActive, addDotBtnsAndClickHandlers } from './vendor/arrows-dots-buttons.js';
import { LightBox } from './vendor/LightBox.js';

export * from './CookieConsentManager.js';

( () => {
	// Show / Hide mobile menu
	const nav = document.querySelector( '.nav' );
	const btn = document.querySelector( '.header__menu-btn' );

	if ( !nav || !btn ) {
		return;
	}

	btn.addEventListener( 'click', () => {
		if ( document.body.classList.contains( 'nav--fade-in' ) ) {
			document.body.classList.remove( 'nav--fade-in' );
			setTimeout( () => {
				document.body.classList.remove( 'nav--visible' );
			}, 400 );
			btn.setAttribute( 'aria-label', 'Show navigation' );
		} else {
			document.body.classList.add( 'nav--visible' );
			setTimeout( () => {
				document.body.classList.add( 'nav--fade-in' );
			}, 100 );
			btn.setAttribute( 'aria-label', 'Hide navigation' );
		}
	} );
} )();

( () => {
	// Show / Hide filter
	const filter = document.querySelector( '.filter__list' );
	const btn = document.querySelector( '.filter__btn' );

	if ( !filter || !btn ) {
		return;
	}

	btn.addEventListener( 'click', () => {
		if ( filter.classList.contains( 'filter__list--fade-in' ) ) {
			filter.classList.remove( 'filter__list--fade-in' );
			setTimeout( () => {
				filter.classList.remove( 'filter__list--visible' );
			}, 400 );
			btn.setAttribute( 'aria-label', 'Show filter list' );
		} else {
			filter.classList.add( 'filter__list--visible' );
			setTimeout( () => {
				filter.classList.add( 'filter__list--fade-in' );
			}, 100 );
			btn.setAttribute( 'aria-label', 'Hide filter list' );
		}
	} );
} )();

( () => {
	// Show / Hide acccordion
	const btns = document.querySelectorAll( '.accordion__button' );

	if ( !btns.length > 0 ) {
		return;
	}

	btns.forEach( btn => {
		btn.addEventListener( 'click', ( e ) => {
			const parent = e.target.closest( '.accordion' );
			if ( !parent ) {
				return;
			}
			if ( parent.classList.contains( 'accordion--fade-in' ) ) {
				parent.classList.remove( 'accordion--fade-in' );
				setTimeout( () => {
					parent.classList.remove( 'accordion--visible' );
				}, 400 );
				btn.setAttribute( 'aria-label', 'Show accordion content' );
			} else {
				parent.classList.add( 'accordion--visible' );
				setTimeout( () => {
					parent.classList.add( 'accordion--fade-in' );
				}, 100 );
				btn.setAttribute( 'aria-label', 'Hide accordion content' );
				// initial embla
				listingItemsEmbla();
			}
		} );
	} );
} )();

// Listing Items Embla as a function so it can be initialised with other code

function listingItemsEmbla() {
	// Embla
	const root_node = document.querySelectorAll( '.embla--listing-items' );

	if ( !root_node.length > 0 ) {
		return;
	}

	for ( let i = 0; i < root_node.length; i++ ) {
		const viewport_node = root_node[i].querySelector( '.embla__viewport' );
		const prev_button_node = root_node[i].querySelector( '.embla__prev' );
		const next_button_node = root_node[i].querySelector( '.embla__next' );
		const embla = EmblaCarousel( viewport_node, { align: 'start', containScroll: 'keepSnaps', loop: false } );
		const remove_prev_next_click_handlers = addPrevNextBtnsClickHandlers( embla, prev_button_node, next_button_node );
		const remove_toggle_prev_next_active = addTogglePrevNextBtnsActive( embla, prev_button_node, next_button_node );
		embla.on( 'destroy', remove_prev_next_click_handlers );
		embla.on( 'destroy', remove_toggle_prev_next_active );
		root_node[i].ten4_embla = embla;
	}
}

( () => {
	// News article - run embla function
	const article__related = document.querySelectorAll( '.article__related' );

	if ( !article__related.length > 0 ) {
		return;
	}

	listingItemsEmbla();
} )();

( () => {
	// Embla
	const root_node = document.querySelectorAll( '.embla__carousel' );

	if ( !root_node.length > 0 ) {
		return;
	}

	for ( let i = 0; i < root_node.length; i++ ) {
		const viewport_node = root_node[i].querySelector( '.embla__viewport' );
		const prev_button_node = root_node[i].querySelector( '.embla__prev' );
		const next_button_node = root_node[i].querySelector( '.embla__next' );
		const dots_node = root_node[i].querySelector( '.embla__dots' );
		const embla = EmblaCarousel( viewport_node, { loop: false } );
		const remove_prev_next_click_handlers = addPrevNextBtnsClickHandlers( embla, prev_button_node, next_button_node );
		const remove_toggle_prev_next_active = addTogglePrevNextBtnsActive( embla, prev_button_node, next_button_node );
		const remove_dot_buttons_click_handlers = addDotBtnsAndClickHandlers( embla, dots_node );
		embla.on( 'destroy', remove_prev_next_click_handlers );
		embla.on( 'destroy', remove_toggle_prev_next_active );
		embla.on( 'destroy', remove_dot_buttons_click_handlers );
		root_node[i].ten4_embla = embla;
	}
} )();

( () => {

	// Page Content Blocks

	const page_content_blocks = document.querySelector( '.page-content__blocks' );
	const page_content_all_blocks = document.querySelectorAll( '.page-content__block' );
	const page_content_nav = document.querySelector( '.page-content__nav' );
	const page_content_links = document.querySelectorAll( '.page-content__nav-link' );

	if ( !page_content_blocks || !page_content_all_blocks.length > 0 || !page_content_nav || !page_content_links.length > 0 ) {
		return;
	}

	window.addEventListener( 'load', () => {
		currentPageContentBlock();
		pageLinkStyle();
		pageNavPosition();
	} );

	window.addEventListener( 'scroll', throttle( () => {
		currentPageContentBlock();
		pageLinkStyle();
		pageNavPosition();
	} ), 50 );

	window.addEventListener( 'resize', throttle( () => {
		currentPageContentBlock();
		pageLinkStyle();
		pageNavPosition();
	} ), 50 );

	// Currently visible page content block - navigation style

	function currentPageContentBlock() {

		const ids = [];
		const tolerance = 20; // how many pixels ABOVE the div the selected link should change

		page_content_all_blocks.forEach( ( page_content_block ) => {
			if ( page_content_block.getBoundingClientRect().top <= tolerance ) {
				if ( page_content_block.id ) {
					ids.push( page_content_block.id );
				}
			}
		} );

		const last_id = ids.pop();

		page_content_links.forEach( ( page_content_link ) => {
			if ( page_content_link.getAttribute( 'data-page-content' ) === last_id ) {
				page_content_link.classList.add( 'page-content__nav-link--current' );
			} else {
				page_content_link.classList.remove( 'page-content__nav-link--current' );
			}
		} );

	}

	// Invert navigation link styles on darker divs

	function pageLinkStyle() {

		page_content_links.forEach( ( page_content_link ) => {
			const link_pos = page_content_link.getBoundingClientRect();
			const link_middle = ( ( link_pos.bottom - link_pos.top ) / 2 ) + link_pos.top;

			page_content_all_blocks.forEach( ( page_content_block ) => {
				if ( ( page_content_block.getBoundingClientRect().top <= link_middle ) && ( page_content_block.getBoundingClientRect().bottom > link_middle ) ) {
					if ( ( page_content_block.classList.contains( 'theme-1' ) ) || ( page_content_block.classList.contains( 'theme-2' ) ) ) {
						page_content_link.classList.add( 'page-content__nav-link--invert' );
					} else {
						page_content_link.classList.remove( 'page-content__nav-link--invert' );
					}
				}
			} );

		} );

	}

	// Remove height of page nav from top of page content blocks (negative margin-top)

	function pageNavPosition() {
		if ( breakpoints.f.width < getWindowWidth() ) {
			const nav_height = page_content_nav.getBoundingClientRect().height;
			page_content_blocks.style.marginTop = '-' + nav_height + 'px';
		} else {
			page_content_blocks.removeAttribute( 'style' );
		}
	}

} )();

( () => {
	// LightBox
	if ( document.querySelector( '.lightbox-trigger' ) != null ) {

		new LightBox();
	}
} )();

const build_load_o_matic = ( options ) => {
	// Load more
	const container = document.querySelector( '.loadomatic' );
	const newsletter = document.querySelector( '.newsletter-sign-up' );
	const footer = document.querySelector( '.footer' );
	if ( !container || !newsletter || !footer ) {
		return;
	}
	const loadomatic = new LoadOMatic( options );
	if ( loadomatic.current_page < loadomatic.total_pages ) {
		newsletter.classList.add( 'newsletter-sign-up--hidden' );
		footer.classList.add( 'footer--hidden' );
	}

	loadomatic.addCallback( () => {
		if ( loadomatic.current_page === loadomatic.total_pages ) {
			newsletter.classList.remove( 'newsletter-sign-up--hidden' );
			footer.classList.remove( 'footer--hidden' );
		}
	} );

	if ( window.CustomEvent ) {
		loadomatic.addCallback( () => {
			window.dispatchEvent( new CustomEvent(
				'loadomatic-loaded'
			) );
		} );
	}

	if ( typeof window.IntersectionObserver === 'undefined' ) {
		loadomatic.trigger.classList.remove( 'loadomatic__trigger--hidden' );
		loadomatic.trigger.addEventListener( 'click', () => {
			loadomatic.loadMore();
		} );
	}

	// turn off below and remove condtional above to test non-observer functionality
	const observer = new IntersectionObserver( ( items ) => {
		for ( let i = 0; i < items.length; i++ ) {
			if ( items[i].isIntersecting ) {
				loadomatic.loadMore();
			}
		}
	}, {
		// Adjust the point in relation to the viewport at which the item is loaded.
		// rootMargin: '-50px 0px -50px 0px',
	} );

	observer.observe( loadomatic.trigger );
};

window.runPageScript = () => {
	if ( typeof window.urbanspace === 'function' ) {
		window.urbanspace( {
			build_load_o_matic: build_load_o_matic
		} );
	}
};

window.runPageScript();

window.dispatchEvent( new Event( 'newpageload', { 'init': true } ) );
